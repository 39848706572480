<script setup>
  import axios from 'axios';

  import { useRouter } from 'vue-router';
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';

  axios.defaults.withCredentials = true;


  const router = useRouter();
  const store = useStore();

  const loading = ref(true);

  const allNews = ref([]);

  const notification = ref('');
  const notificationPresent = ref(false);
  const notificationTimeoutId = ref(null);


  const makeDateFriendly = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const searchGames = async (intent) => {
    await store.dispatch('setIntent', intent);
    router.push('/all-games');
  };


  const generateGameDetailSlug = (gameTitle) => {
      return gameTitle
        .toLowerCase()                       // Convert to lowercase
        .replace(/[^a-z0-9\s-]/g, '')        // Remove invalid characters
        .trim()                              // Remove leading/trailing spaces
        .replace(/\s+/g, '-')                // Replace spaces with hyphens
        .replace(/-+/g, '-')                 // Remove duplicate hyphens
  }

  const getAllNews = async () => {
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-all-news`);

      let allGames = await require('../assets/data/games-info-all.json');
      allGames = allGames['data'];

      if (response.status === 200) {
        const newsList = response.data.all_news;

        // Get the full host with protocol
        const host = window.location.origin;

        // Iterate through all news items using for...of to handle async/await properly
        for (const news of newsList) {

          // Check if the dedicated_link_url doesn't start with 'http'
          if (!news.dedicated_link_url.startsWith('http')) {
            // Find the game related to the dedicated_link_url
            const game = allGames.find(
              game => game.title === news.dedicated_link_url
            );

            // Ensure game is found before using it
            if (game) {
              const gameTitle = generateGameDetailSlug(game.title);

              // Check if the news is a review or a tip by checking the
              // title
              if (news.title.toLowerCase().includes('submitted a review')) {
                news.dedicated_link_url = `${host}/game/${gameTitle}/${game.xbox_api_id}/reviews`;
              } else if (news.title.toLowerCase().includes('submitted a tip')) {
                news.dedicated_link_url = `${host}/game/${gameTitle}/${game.xbox_api_id}/tips`;
              } else {
                news.dedicated_link_url = `${host}/game/${gameTitle}/${game.xbox_api_id}/details`;
              }
            }
          }
        }

        // Update the reactive property directly
        allNews.value = [...newsList];
      }
    } catch (e) {
      console.error('Error fetching news:', e);
    }
  };

  const openLinkInNewWindow = async(url) => {
    window.open(url, '_blank');
  }

  const voteNews = async(newsId, vote) => {
      const inputData = {
        news_id: newsId,
        vote: vote
      };

      try {
        const res = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/vote-news/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

          // Check if res status is 200
          if (res.status === 200) {

            // We add a vote to the news score for the game
            let voteNum;
            if (vote) {
              voteNum = 1;
            } else {
              voteNum = -1;
            }
            const newsIndex = allNews.value.findIndex(news => news.id === newsId);

            allNews.value[newsIndex].total_votes += voteNum;
            allNews.value[newsIndex].user_vote = res.data.vote_status;

            // If the user submitted a new news vote, we update their points
            const total_points_accumulated = res.data.total_points_accumulated;
            
            if (total_points_accumulated !== undefined) {

              await store.dispatch(
                'setGameKarmaPoints', total_points_accumulated
              );
            }
          }

      } catch (error) {
        console.error('Could not submit the news vote.', error);

        if (error.response.status === 409) {
          const msg = 'Vote not submitted. '
                    + 'You have already voted on this news.';
          
          notification.value = msg;
          notificationPresent.value = true;

          notificationTimeoutId.value = setTimeout(() => {
            notificationPresent.value = false;
          }, 3000);
        }
      }
    }


  onMounted(() => {
    loading.value = true;

    getAllNews();

    loading.value = false;
  })



</script>

<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div>
        <div class="mb-2">
          <h1 class="text-xl font-bold">Latest News</h1>
          <div>
            <span @click="searchGames('View Just Released')" class="text-primary hover:underline cursor-pointer">
              Just Released
            </span> | <span @click="router.push('/blog')" class="text-green-300 font-bold hover:underline cursor-pointer">
              Blog
            </span> | <span @click="searchGames('View Leaving Soon')" class="text-primary hover:underline cursor-pointer">
              Leaving Soon
            </span>
          </div>
        </div>
        <div v-if="!loading" class="h-[90vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
          <div v-for="news in allNews" :key=news.id class="flex flex-col space-y-4">
            <div @click="openLinkInNewWindow(news.dedicated_link_url)" class="text-left bg-slate-800 p-4 rounded-lg my-1 cursor-pointer">
              <img :src="news.image_url" alt="news image" class="rounded-lg">
              <h2 class="font-bold mt-2 break-words">{{ news.title }}</h2>
              <p class="mt-2 break-words">{{ news.description }}</p>
              <a class="text-primary underline mt-2 inline-block break-all">{{ news.dedicated_link_url }}</a>
              <div class="flex items-center justify-between mt-2">
                <div>
                  <font-awesome-icon 
                    @click.stop="voteNews(news.id, true)" 
                    :icon="['fas', 'arrow-up']" 
                    :class="['cursor-pointer hover:text-green-500', {'text-green-500': news.user_vote === true}]"
                  />
                  <span class="text-white px-2">{{ news.total_votes }}</span>
                  <font-awesome-icon 
                    @click.stop="voteNews(news.id, false)" 
                    :icon="['fas', 'arrow-down']" 
                    :class="['cursor-pointer hover:text-red-500', {'text-red-500': news.user_vote === false}]"
                  />
                </div>
                <div class="text-gray-300 text-xs text-right">{{ makeDateFriendly(news.updated_on) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-center items-center mt-10">
            <svg class="animate-spin h-10 w-10 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V4a10 10 0 00-10 10h2zm2 8a8 8 0 018-8h2a10 10 0 00-10-10v2zm8 2a8 8 0 01-8-8H4a10 10 0 0010 10v-2z"></path>
            </svg>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>

      <!-- NOTIFICATION (STARTS) -->
      <div v-if="notificationPresent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
        <div class="p-4 relative">
          <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="mb-2 text-center flex justify-center items-center">
            <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <div class="ms-3">
            <p class="text-lg text-left text-gray-200 pl-2">{{ notification }}</p>
          </div>
        </div>
      </div>
      <!-- NOTIFICATION (ENDS) -->

  </body>
</template>

<style scoped>

</style>
