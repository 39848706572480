<template>
    <header class="text-primary mb-3 flex flex-wrap md:justify-start md:flex-nowrap z-40 w-full text-sm py-4">
        <nav class="w-full px-4 md:flex md:items-center md:justify-between md:px-6 lg:px-8" aria-label="Global">
            <div class="flex items-center justify-between">
              <img @click="navigateToGamePickerPage" class="h-7 w-7 cursor-pointer" src="/images/xbox-logo.svg" alt="Xbox Logo">
              <div class="flex-none font-semibold font-mittleschriftcaps cursor-pointer pl-2" @click="navigateToGamePickerPage" href="#">GAME PASS</div>
              <div class="z-10"><router-link to="/" class="flex-none font-medium hover:underline pl-2 whitespace-nowrap cursor-pointer" active-class="active-link">Game Picker</router-link></div>
              <div class="md:hidden">
                  <button type="button" class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-700 hover:border-gray-600 font-medium text-gray-300 hover:text-white shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-900 focus:ring-blue-600 transition-all text-sm" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
                      <svg class="hs-collapse-open w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                      </svg>
                      <svg class="hs-collapse-close w-4 h-4 hidden" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                  </button>
              </div>
            </div>
            <div id="navbar-collapse-with-animation" class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block">
              <div v-if="!loading" class="flex flex-col gap-5 mt-5 md:flex-row md:items-center md:justify-end md:mt-0 md:pl-5">
                  <router-link v-if="authenticated" to="/my-games" class="font-medium hover:underline" active-class="active-link">My Games</router-link>
                  <router-link v-if="authenticated" to="/all-games" class="font-medium hover:underline" active-class="active-link">Search Games</router-link>
                  <a v-else class="font-medium hover:underline" @click="navigateToSignUpPage('all-games')" href="#">Search Games</a>
                  <router-link to="/recommendations" class="font-medium hover:underline" active-class="active-link">AI-Recs</router-link>
                  <router-link v-if="authenticated" to="/news" class="font-medium hover:underline" active-class="active-link">News</router-link>
                  <a v-else class="font-medium hover:underline" @click="navigateToSignUpPage('news')" href="#">News</a>
                  <router-link to="/social" class="font-medium hover:underline" active-class="active-link">Social</router-link>
                  <router-link to="/blog" class="font-medium hover:underline" active-class="active-link">Blog</router-link>
                  <div v-if="!authenticated" class="p-2 cursor-pointer">
                    <div @click="navigateToSignUpPage" class="font-medium hover:underline cursor-pointer">Join Community</div> 
                    <div class="my-1 text-gray-400 text-xs text-middle">
                      <hr class="text-primary border-green-200" />
                    </div>
                    <div @click="navigateToLoginPage" class="font-medium hover:underline cursor-pointer">Login</div> 
                  </div>
                  <div v-if="authenticated" @click="navigateToProfilePage" class="flex items-center justify-center text-xl hover:text-green-300 cursor-pointer">
                      <font-awesome-icon icon="fa-solid fa-circle-user"/>
                  </div>
                  <div v-if="authenticated && userGameKarmaPoints !== null" @click="navigateToStorePage" type="button" class="flex items-center justify-center cursor-pointer">
                    <svg :class="{ vibrating: isAnimating }" width="30" height="30" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <!-- Background circle -->
                      <circle cx="100" cy="100" r="90" fill="gold" stroke="orange" stroke-width="5" />

                      <!-- Inner circle -->
                      <circle cx="100" cy="100" r="70" fill="goldenrod" />

                      <!-- Placeholder text -->
                      <text x="100" y="105" font-family="Arial" font-weight="bold" :font-size="gameKarmaPointsHolderFontSize" fill="white" text-anchor="middle" dominant-baseline="middle">
                        {{ userGameKarmaPoints }}
                      </text>
                    </svg>
                  </div>
              </div>
            </div>
        </nav>

    </header>
</template>

<script setup>
    import axios from 'axios';

    import { onMounted, ref, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';


    const router = useRouter();
    const store = useStore();
    const loading = ref(true);

    const authenticated = ref(false);

    // Used for the user points/karma system
    const userGameKarmaPoints = ref(null);
    const gameKarmaPointsHolderFontSize = ref('70');
    const isAnimating = ref(false);


    onMounted(async () => {
      loading.value = true;

      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'  // Necessary for passing of login cookies from backend
        })

        const data = await response.json();  // Parse the JSON data from the response

        // Set the gamer ID in the store
        await store.dispatch('setGamerId', data.user.xbox_gamertag);

        if (response.status === 403) {
          await store.dispatch('setAuth', false);
          authenticated.value = false;
        } else if (response.status === 200) {
          await store.dispatch('setAuth', true);
          authenticated.value = true;
        }

      await getUserPoints();

      // Set the font size of the gameKarmaPointsHolder
      if (userGameKarmaPoints.value.toString().length > 3) {
        gameKarmaPointsHolderFontSize.value = '60';
      }

      } catch (e) {
        await store.dispatch('setAuth', false);
        authenticated.value = false;
      }

      loading.value = false;

    })

    // Watcher for the gameKarmaPoints
    watch(() => store.state.gameKarmaPoints, (newPoints) => {
      userGameKarmaPoints.value = newPoints;
    }, { immediate: true });

    // Watch for changes in userGameKarmaPoints
    watch(() => userGameKarmaPoints.value, (newVal, oldVal) => {
      if (oldVal !== null && newVal !== oldVal) {
        isAnimating.value = true;
        setTimeout(() => {
          isAnimating.value = false;
        }, 1000);  // Duration of the animation
      }
    });

    const navigateToGamePickerPage = () => {
        router.push('/');
    }

    const navigateToSignUpPage = async (intent) => {
        await store.dispatch('setIntent', intent);
        router.push('/signup');
    }

    const navigateToLoginPage = () => {
        router.push('/login');
    }

    const navigateToProfilePage = () => {
        router.push('/profile');
    }

    const navigateToStorePage = () => {
        router.push('/store');
    }

    const getUserPoints = async () => {
      // Make the API call
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-points/`,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

        if (response.data) {
          await store.dispatch('setGameKarmaPoints', response.data.points_balance);
        }

      } catch (error) {
        console.error('Could not retrieve the points for the user.', error);
      }
    }

</script>

<style scoped>

.active-link {
  text-decoration: underline;
}

@keyframes vibrate {
  0% { transform: translate(0); }
  10% { transform: translate(1.5px, 1.5px); }
  20% { transform: translate(-1.5px, -1.5px); }
  30% { transform: translate(1.5px, -1.5px); }
  40% { transform: translate(-1.5px, 1.5px); }
  50% { transform: translate(1.5px, 1.5px); }
  60% { transform: translate(-1.5px, -1.5px); }
  70% { transform: translate(1.5px, -1.5px); }
  80% { transform: translate(-1.5px, 1.5px); }
  90% { transform: translate(1.5px, 1.5px); }
  100% { transform: translate(0); }
}

.vibrating {
  animation: vibrate 1s;
}



</style>
