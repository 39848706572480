<template>
    <body class="bg-slate-900 min-h-full">
        <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
            <HeaderComponent />

            <div class="font-bold text-2xl text-white text-center my-4">
                <h1>Our Recent Posts</h1>
            </div>

            <div class="flex flex-col space-y-4">
                <div class="text-left bg-slate-800 p-4 rounded-lg my-1 cursor-pointer" @click="$router.push('blog/best-single-player-shooter-games-xbox-game-pass-2025')">
                <img src="@/assets/blog/best-single-player-shooter-games.png" alt="best single player shooter games on xbox game pass in 2025" class="rounded-lg">
                <h2 class="font-bold mt-2 break-words">Best Single Player Shooter Games on Xbox Game Pass in 2025</h2>
                <p class="mt-2 break-words">
                  Discover the ultimate lineup of single-player shooter masterpieces on 
                  Xbox Game Pass in 2025! From the futuristic thrill of mech warfare in 
                  Titanfall 2 to the epic saga of Halo and the mind-bending time-loop 
                  action of Deathloop, our carefully curated guide delves into the games 
                  that are redefining the shooter genre. Whether you’re after 
                  heart-pounding combat, innovative gameplay twists, or immersive 
                  narratives that keep you on the edge of your seat, this blog post 
                  has something for every shooter enthusiast. Uncover detailed reviews, 
                  impressive award wins, and essential stats that reveal why these titles 
                  are a must-play. Ready to level up your gaming experience? Dive in and 
                  find your next unforgettable adventure!
                </p>
                <a href="https://gamepasspicker.com/blog/best-single-player-shooter-games-xbox-game-pass-2025" class="text-primary underline mt-2 inline-block break-all">
                    https://gamepasspicker.com/blog/best-single-player-shooter-games-xbox-game-pass-2025
                </a>
                <div class="flex items-center justify-between mt-2">
                    <div>
                    <font-awesome-icon 
                        icon="['fas', 'arrow-up']" 
                        class="cursor-pointer hover:text-green-500 text-green-500"
                    />
                    <span class="text-white px-2"></span>
                    <font-awesome-icon 
                        icon="['fas', 'arrow-down']" 
                        class="cursor-pointer hover:text-red-500"
                    />
                    </div>
                    <div class="text-gray-300 text-xs text-right">
                        Feb 18, 2025
                    </div>
                </div>
                </div>
            </div>

            <div class="flex flex-col space-y-4">
                <div class="text-left bg-slate-800 p-4 rounded-lg my-1 cursor-pointer" @click="$router.push('blog/best-indie-games-xbox-game-pass-2025')">
                <img src="@/assets/blog/best-indie-games.png" alt="best indie games on xbox game pass in 2025" class="rounded-lg">
                <h2 class="font-bold mt-2 break-words">Best Indie Games on Xbox Game Pass in 2025</h2>
                <p class="mt-2 break-words">
                  Discover the Best Indie Games on Xbox Game Pass in 2025! This curated lineup 
                  features award-winning titles like Celeste, INSIDE, Dead Cells, Hollow Knight, 
                  Firewatch, Stardew Valley, Slay The Spire, Cocoon, Citizen Sleeper 2, Sifu, 
                  Among Us, Planet of Lana, and Spiritfarer. Each game showcases innovative 
                  gameplay, captivating narratives, and stunning art—from precision platformers 
                  and atmospheric puzzles to immersive adventures and heartfelt management 
                  experiences. Dive into these indie gems and experience the creative revolution 
                  redefining gaming on the Xbox platform.
                </p>
                <a href="https://gamepasspicker.com/blog/best-indie-games-xbox-game-pass-2025" class="text-primary underline mt-2 inline-block break-all">
                    https://gamepasspicker.com/blog/best-indie-games-xbox-game-pass-2025
                </a>
                <div class="flex items-center justify-between mt-2">
                    <div>
                    <font-awesome-icon 
                        icon="['fas', 'arrow-up']" 
                        class="cursor-pointer hover:text-green-500 text-green-500"
                    />
                    <span class="text-white px-2"></span>
                    <font-awesome-icon 
                        icon="['fas', 'arrow-down']" 
                        class="cursor-pointer hover:text-red-500"
                    />
                    </div>
                    <div class="text-gray-300 text-xs text-right">
                        Feb 18, 2025
                    </div>
                </div>
                </div>
            </div>
            
            <div class="flex flex-col space-y-4">
                <div class="text-left bg-slate-800 p-4 rounded-lg my-1 cursor-pointer" @click="$router.push('blog/best-action-adventure-games-xbox-game-pass-2025')">
                <img src="@/assets/blog/best-action-adventure-games.png" alt="best action adventure games on xbox game pass in 2025" class="rounded-lg">
                <h2 class="font-bold mt-2 break-words">Best Action-Adventure Games on Xbox Game Pass in 2025</h2>
                <p class="mt-2 break-words">
                    Discover the Best Action-Adventure Games on Xbox Game Pass in 2025! Get 
                    ready to embark on thrilling quests that blend cinematic storytelling, 
                    intense combat, and mind-bending puzzles. Whether you’re racing against 
                    time with Indiana Jones in “Indiana Jones and the Great Circle,” 
                    unraveling supernatural mysteries in “Control Ultimate Edition,” or 
                    delving into the historical and emotional depths of 
                    “A Plague Tale: Requiem,” there’s an adventure here for every kind of 
                    explorer. Experience the vibrant streets of 1980s Japan in “Yakuza 0,” 
                    retrace the origins of iconic heroes in 
                    “Tomb Raider: Definitive Edition,” or test your skills with the 
                    challenging combat of “Ninja Gaiden 2 Black.” From the dark vigilante 
                    thrills of “Batman: Arkham Knight” and the survival horror of 
                    “Resident Evil 2” to the strategic freedom of “Dishonored 2” and 
                    the epic saga of “Star Wars Jedi: Survivor,” Xbox Game Pass delivers 
                    a dynamic lineup of action-adventure experiences. Whether you crave 
                    intricate narratives, adrenaline-pumping battles, or rich exploration, 
                    this curated list is your guide to the ultimate adventures waiting for 
                    you. Gear up and get ready to dive into your next epic journey! 🎮
                </p>
                <a href="https://gamepasspicker.com/blog/best-action-adventure-games-xbox-game-pass-2025" class="text-primary underline mt-2 inline-block break-all">
                    https://gamepasspicker.com/blog/best-action-adventure-games-xbox-game-pass-2025
                </a>
                <div class="flex items-center justify-between mt-2">
                    <div>
                    <font-awesome-icon 
                        icon="['fas', 'arrow-up']" 
                        class="cursor-pointer hover:text-green-500 text-green-500"
                    />
                    <span class="text-white px-2"></span>
                    <font-awesome-icon 
                        icon="['fas', 'arrow-down']" 
                        class="cursor-pointer hover:text-red-500"
                    />
                    </div>
                    <div class="text-gray-300 text-xs text-right">
                        Feb 5, 2025
                    </div>
                </div>
                </div>
            </div>

            <div class="flex flex-col space-y-4">
                <div class="text-left bg-slate-800 p-4 rounded-lg my-1 cursor-pointer" @click="$router.push('blog/best-rpg-games-xbox-game-pass-2025')">
                <img src="https://img.youtube.com/vi/1dO9bBKgpEo/maxresdefault.jpg" alt="best rpgs on gamepass in 2025" class="rounded-lg">
                <h2 class="font-bold mt-2 break-words">Best RPG Games on Xbox Game Pass in 2025</h2>
                <p class="mt-2 break-words">
                    Discover the Best Role-Playing Games on Xbox Game Pass in 2025! Dive into 
                    epic adventures and thrilling storylines that define the RPG genre. 
                    From the dragon-filled open world of Skyrim to the post-apocalyptic 
                    chaos of Fallout 4, there’s a game for every kind of adventurer. 
                    Experience the dark fantasy of Diablo IV, unravel mysteries in the 
                    medieval world of Pentiment, or navigate the cyberpunk-inspired 
                    survival drama of Citizen Sleeper. Whether you prefer tactical combat, 
                    open-world exploration, or gripping narratives, Xbox Game Pass delivers 
                    unforgettable journeys for RPG fans. Hopefully this list can help you
                    decide what to play next. 🎮
                </p>
                <a href="https://gamepasspicker.com/blog/best-rpg-games-xbox-game-pass-2025" class="text-primary underline mt-2 inline-block break-all">
                    https://gamepasspicker.com/blog/best-rpg-games-xbox-game-pass-2025
                </a>
                <div class="flex items-center justify-between mt-2">
                    <div>
                    <font-awesome-icon 
                        icon="['fas', 'arrow-up']" 
                        class="cursor-pointer hover:text-green-500 text-green-500"
                    />
                    <span class="text-white px-2"></span>
                    <font-awesome-icon 
                        icon="['fas', 'arrow-down']" 
                        class="cursor-pointer hover:text-red-500"
                    />
                    </div>
                    <div class="text-gray-300 text-xs text-right">
                        Jan 23, 2025
                    </div>
                </div>
                </div>
            </div>

            <FooterComponent />
        </div>
    </body>
</template>

<script setup>
import { useHead } from '@vueuse/head';

import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
import FooterComponent from '@/components/Layout/FooterComponent.vue';

useHead({
  title: 'Best Xbox Game Pass Games: Top Picks & Hidden Gems for Gamers',
  meta: [
    {
      name: 'description',
      content:
        'Discover the best Xbox Game Pass games, featuring top picks and hidden gems that every gamer should play. Read detailed reviews and recommendations to elevate your gaming experience.',
    },
    {
      name: 'keywords',
      content:
        'Xbox Game Pass, best games, top picks, hidden gems, Xbox games, game reviews, gaming recommendations, must-play games, video games, Xbox',
    },
    { property: 'og:title', content: 'Best Xbox Game Pass Games: Top Picks & Hidden Gems for Gamers' },
    {
      property: 'og:description',
      content:
        'Explore our curated guide to the best Xbox Game Pass games. Find top picks, hidden gems, and detailed reviews to level up your gaming experience.',
    },
    { property: 'og:type', content: 'article' },
    { property: 'og:url', content: 'https://gamepasspicker.com/blog' },
    { property: 'og:image', content: 'https://www.thexboxhub.com/wp-content/uploads/2021/07/xbox-game-pass-logo-3.jpeg' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: 'Best Xbox Game Pass Games: Top Picks & Hidden Gems for Gamers' },
    {
      name: 'twitter:description',
      content:
        'Discover top Xbox Game Pass games with our detailed guide, featuring must-play titles and hidden gems for the ultimate gaming experience.',
    },
    { name: 'twitter:image', content: 'https://www.thexboxhub.com/wp-content/uploads/2021/07/xbox-game-pass-logo-3.jpeg' },
  ],
  link: [
    { rel: 'canonical', href: 'https://gamepasspicker.com/blog' },
  ],
  script: [
    {
      type: 'application/ld+json',
      children: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": "Best Xbox Game Pass Games: Top Picks & Hidden Gems for Gamers",
        "description": "Discover the best Xbox Game Pass games, featuring top picks and hidden gems that every gamer should play. Read detailed reviews and recommendations to elevate your gaming experience.",
        "image": "https://www.thexboxhub.com/wp-content/uploads/2021/07/xbox-game-pass-logo-3.jpeg",
        "author": {
          "@type": "Person",
          "name": "Mihai Avram"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Game Picker",
          "logo": {
            "@type": "ImageObject",
            "url": require('@/assets/logo.png')
          }
        },
        "datePublished": "2025-02-05",
        "dateModified": "2025-02-05"
      }),
    },
  ],
});


</script>

<style scoped>
</style>